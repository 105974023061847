.parent {
  display: flex;
  width: 100vw;
  height: 10vh;
  position: fixed;
  bottom: 0px;
  z-index: 9;
  align-items: center;
  justify-content: center;
}
.content {
  display: flex;
  width: 90%;
  height: 90%;
  border: 1px solid black;
  background: rgba(51, 11, 1, 0.63);
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(124, 54, 36, 0.3);
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: white;
}
