.parent {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-bottom: 50px;
  margin-top: 25px;
}
.content {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: center;
}
.parentContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 35px;
  gap: 10px;
}
.title {
  font-size: 1.8vw;
  font-weight: 600;
  color: #3d3c3c;
}
.desc {
  font-size: 1vw;
  font-weight: 400;
  color: #020202;
}
@media (max-width: 768px) {
  .title {
    font-size: 5vw;
    font-weight: 600;
    color: #3d3c3c;
  }
  .desc {
    font-size: 3vw;
    font-weight: 400;
    color: #020202;
  }
  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
}
