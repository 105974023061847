/* Hero.module.css */

/* Background gradient */
.backgroundGradient {
  min-width: 100%;
  position: absolute;
  background: linear-gradient(
    75deg,
    rgba(255, 50, 50, 1) 8%,
    rgba(238, 142, 78, 1) 46%,
    rgba(133, 49, 117, 1) 87%
  );
  height: 75%;
  border-bottom-left-radius: 40%;
  z-index: -1;
}

/* Left container */
.leftContainer {
  display: flex;
  flex: 0.5;
  flex-direction: column;
  justify-content: center;
  padding-left: 15%;
}

.brandText {
  font-size: 3vw;
  font-weight: 600;
  color: white;
}

.title {
  color: white;
}

/* Animated text */
.animationText {
  margin-top: 40px;
  margin-bottom: 20px;
  color: white;
  font-size: 2em;
  display: block;
}

/* Subtitle */
.subTitle {
  font-size: 1.2em;
  color: white;
}

/* Button container */
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  /* border: 1px solid black; */
  width: 100%;
}

/* Right container for animation */
.rightContainer {
  display: flex;
  flex: 0.5;
  align-items: center;
  justify-content: center;
}

/* Main container for hero section */
.heroContainer {
  display: flex;
  width: 100%;
  height: 85vh;
  /* padding-top: 80px; */
}
@media (max-width: 768px) {
  .heroContainer {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
  }
  .animationText {
    margin-top: 40px;
    margin-bottom: 20px;
    color: white;
    font-size: 1em;
    display: block;
  }
  .brandText {
    font-size: 10vw;
    font-weight: 600;
    color: white;
    margin-top: 20%;
  }
  .subTitle {
    font-size: 4vw;
    color: white;
  }
  .leftContainer {
    display: flex;
    flex: 0.5;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 5%;
    text-align: left;
    padding-top: 25%;
  }
}
