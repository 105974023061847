.serviceheader {
  display: flex;
  font-size: 2vw;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 35px;
  color: rgb(87, 84, 84);
  padding: 10px 30px 10px 30px;
  text-align: center;
  margin-left: 25px;
}
@media (max-width: 768px) {
  .serviceheader {
    display: flex;
    font-size: 5vw;
    font-weight: 600;
    /* width: 20%; */
    height: 30px;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    border-radius: 35px;
    color: rgb(87, 84, 84);

    text-align: center;
    margin-left: 25px;
  }
}
