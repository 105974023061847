.parent {
  display: flex;
  flex-direction: column;
}
.parentContent {
  display: flex;
  margin-top: "20px";
  background: linear-gradient(
    75deg,
    rgba(183, 9, 71, 1) 8%,
    rgba(232, 75, 14, 1) 49%,
    rgba(210, 18, 1, 1) 87%
  );
  margin-top: 30px;
  padding: 5%;
  padding-top: 10%;
  border-top-right-radius: 50%;
}
.contactFormContainer {
  display: flex;
  width: 30%;
  margin-left: 60px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  padding: 50px;
  border-radius: 25px;
}
@media (max-width: 768px) {
  .parentContent {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    background: linear-gradient(
      75deg,
      rgba(183, 9, 71, 1) 8%,
      rgba(232, 75, 14, 1) 49%,
      rgba(210, 18, 1, 1) 87%
    );
    padding: 5%;
    padding-top: 10%;
    border-top-right-radius: 10%;
    padding-bottom: 30%;
  }
  .contactFormContainer {
    display: flex;
    width: 100%;
    margin-left: 0px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    padding: 0px;
    border-radius: 25px;
  }
}
